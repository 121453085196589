@import url('https://rsms.me/inter/inter.css');

html,
body,
#root,
.bg,
canvas {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background: #171717;
  font-family: 'Inter var', sans-serif;
}

.logo {
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, 0);
  width: min(90%, 700px);
  left: 50%;
  top: 8px;
}

.icon-box {
  background-color: #171717bc;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  width: min(80%, 256px);
}

.social {
  display: inline;
  margin: 10px;
}
